@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Muli:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Muli Light 300*/

/*Lora Regular 400 and Italic*/

/*Rock Salt Regular 400*/

@page {
    margin: 15mm;
}

@media print {
    h2.printTitle {
        font-size: 24px !important;
        color: #494949 !important;
    }
    h3,
    p,
    span,
    li {
        color: #494949 !important;
    }
    p,
    li {
        font-size: 12px !important;
    }
}

a {
    color: #3e739b;
    text-decoration: none;
    font-weight: 600;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

p {
    font-size: 16px;
    text-align: left;
    font-family: "Muli", sans-serif;
    white-space: pre-wrap;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.site-header {
    background: #194666;
    height: 95px;
    position: fixed;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.site-header.small {
    height: 60px;
    transition: all 0.5s ease-in-out;
}

.site-branding {
    width: 15%;
    padding-top: 5px;
}

.main-navigation {
    clear: both;
    padding-top: 5px;
    display: block;
    width: 65%;
    font-weight: 500;
    font-style: normal;
    margin: 0 auto;
}

.main-navigation ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.main-navigation ul ul {
    /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2); */
    float: left;
    position: absolute;
    top: 100%;
    left: -999em;
    z-index: 99999;
    width: 15rem;
    padding-top: 2rem;
    background: #194666;
}

.main-navigation li ul li {
    display: block;
    text-align: left;
    padding: 10px;
}

.main-navigation ul ul ul {
    left: -999em;
    top: 0;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
    left: 100%;
}

.main-navigation ul ul a {
    width: 200px;
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
    left: auto;
}

.main-navigation li {
    position: relative;
    padding: 0px 20px;
    display: inline-block;
}

.main-navigation a {
    font-family: "Muli", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
}

.main-navigation a:hover {
    color: #33ccff;
    text-decoration: none;
}

.main-navigation .menu-main-menu-container {
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.search-start-planning {
    width: 20%;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.header-search-field input {
    border: 0;
    color: #fff !important;
    font-family: "Lora", serif;
}

.header-search-field .MuiInput-underline:before {
    border-bottom: 1px solid #fff !important;
}

.login-navigation a {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: none;
    color: #fff;
}

.login-navigation a:hover {
    color: #33ccff;
    text-decoration: none;
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
    display: block;
}

@media screen and (min-width: 37.5em) {
    .menu-toggle {
        display: none;
    }
    .main-navigation ul {
        display: block;
    }
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
    margin: 0 0 1.5em;
    overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
    float: left;
    width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

/*Mobile Menu Styles */
.mobile-menu-panel .MuiPaper-root.MuiDrawer-paper {
    background-color: #194666;
}

.mobile-menu-panel
    .mobile-menu-item:nth-of-type(1)
    .MuiCollapse-container.MuiCollapse-entered {
    width: 50%;
    float: right;
}

.embla {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
    padding: 0px 15px 10px 15px;
}

.embla__image__viewport {
    overflow: hidden;
    width: 100%;
    padding: 0;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
}

.embla__slide {
    position: relative;
    min-width: 33%;
    padding: 15px 0px 15px 15px;
}

@media (max-width: 600px) {
    .embla__slide {
        min-width: 100%;
    }
}

.embla__image__slide {
    position: relative;
    min-width: 100%;
    padding: 0;
}

.embla__slide__inner {
    position: relative;
    overflow: hidden;
    height: 190px;
}

.embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
}

.embla__button--prev {
    left: 27px;
}

.embla__button--next {
    right: 27px;
}

.embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 12px;
    height: 25px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
}

.embla__dot:after {
    background-color: #fafafa00;
    width: 100%;
    height: 12px;
    border-radius: 12px;
    content: "";
    border: #fafafa solid 1px;
}

.embla__dot.is-selected:after {
    background-color: #fafafa;
    opacity: 1;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #494949;
}

.MuiFormLabel-root {
    font-size: 16px;
}

.MuiFormControlLabel-root span {
    font-size: 16px;
}
@keyframes move-around {
    0% {
        transform: translate(-40%, -35%);
    }

    25% {
        transform: translate(40%, -35%);
    }

    50% {
        transform: translate(40%, 35%);
    }

    75% {
        transform: translate(-40%, 35%);
    }

    100% {
        transform: translate(-40%, -35%);
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }
}

.logo {
    animation: scale 2s infinite;
}

/* Detailed Itinerary Styles */
.detailed-itinerary .MuiAppBar-colorDefault {
    background-color: #494949;
}

.detailed-itinerary .MuiTab-textColorPrimary,
.detailed-itinerary .MuiTab-textColorPrimary.Mui-selected {
    color: #ffffff !important;
}

.detailed-itinerary .PrivateTabIndicator-colorPrimary-234 {
    background-color: #dbaa79 !important;
}

.detailed-itinerary .PrivateTabIndicator-root-233 {
    bottom: 15px;
}

.detailed-itinerary .MuiTabs-flexContainer {
    max-width: 960px;
    margin: 0 auto;
    padding: 10px 0;
}

.detailed-itinerary .daily-breakdown .MuiPaper-elevation1 {
    box-shadow: none;
}

.detailed-itinerary .daily-breakdown .MuiExpansionPanelSummary-root {
    padding: 0;
}

.detailed-itinerary .daily-breakdown .MuiExpansionPanel-root:before {
    display: none;
}

.detailed-itinerary .daily-breakdown .MuiIconButton-edgeEnd {
    margin-right: 180px;
    padding: 0;
}

.detailed-itinerary .daily-breakdown .slick-slider {
    max-width: 420px;
    margin: 0 auto;
    position: sticky;
    top: 115px;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-included ul {
    margin: 0;
    padding: 0;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-included li {
    text-align: left;
    list-style-position: inside;
    font-size: 16px;
}

.detailed-itinerary .daily-breakdown .slick-dots {
    bottom: 20px !important;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-grid:nth-of-type(2n) {
    flex-direction: row-reverse;
}

.detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .daily-breakdown-grid-item {
    text-align: right;
}

.detailed-itinerary .daily-breakdown .daily-breakdown-grid-item {
    text-align: left;
}

/* .detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .daily-activities-panel {
    float: right;
} */

.detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .MuiExpansionPanelSummary-content {
    display: block;
    text-align: right;
}

.detailed-itinerary
    .daily-breakdown
    .daily-breakdown-grid:nth-of-type(2n)
    .MuiIconButton-edgeEnd {
    margin-right: 0;
}

/* Detailed Itinerary > Experience Details Content */
/* .detailed-itinerary .experience-details .slick-dots li button,
.more-from-bandr .slick-dots li button {
    border: 1px solid #000 !important;
} */

.detailed-itinerary
    .experience-details
    .slick-dots
    li.slick-active
    button:before,
.more-from-bandr .slick-dots li.slick-active button:before {
    color: #000 !important;
}

.detailed-itinerary .experience-details .experience-details-inclusions-panel {
    box-shadow: none;
}

.detailed-itinerary
    .experience-details
    .experience-details-inclusions-panel
    .MuiExpansionPanelSummary-root {
    padding: 0;
}

.detailed-itinerary
    .experience-details
    .experience-details-inclusions-panel
    .MuiIconButton-edgeEnd {
    margin-right: 248px;
    padding: 5px;
}

.detailed-itinerary
    .experience-details
    .experience-details-inclusions-panel
    .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
}

.detailed-itinerary .MuiBox-root {
    padding: 0;
}

.detailed-itinerary .MuiTab-wrapper {
    font-family: "Muli", "sans-serif";
}

.MuiBottomNavigationAction-root.Mui-selected {
    color: #194666 !important;
}

.MuiBottomNavigationAction-root {
    min-width: 50px !important;
}

.hotels-container .MuiGrid-container {
    display: flex !important;
}

/*Footer Custom Styles*/
.site-footer {
    height: 600px;
    background: black;
    margin-top: 32px;
}

.footer-wrapper {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin-right: auto;
    padding: 20px;
}

.footer-logo {
    width: 20%;
}

.footer-logo-container {
    max-width: 160px;
    margin-right: auto;
}

.footer-navigation {
    width: 80%;
}

.footer-navigation-container {
    max-width: 830px;
    margin-right: auto;
    text-align: left;
    color: white;
}

.footer-menu-item ul {
    padding: 0;
}

.footer-menu-item ul li a:hover {
    color: #3cf;
}

.footer-navigation-container p {
    font-weight: 600;
    font-family: "Muli", sans-serif;
}

.footer-navigation-container ul {
    margin: 1rem 0;
}

.footer-navigation-container li {
    list-style: none;
}

.footer-navigation-container a {
    color: white;
    font-size: 14px;
    font-weight: 500;
    font-family: "Muli", sans-serif;
}

.contact-us-container {
    text-align: left;
    font-weight: 600;
    padding: 0 20px 20px 20px;
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    display: flex;
}

.contact-us-container a {
    font-family: "Muli", sans-serif;
    font-weight: 500;
}

.contact-us-container p {
    color: #fff;
}

.contact-us-container span {
    color: #3cf;
}

.site-info {
    height: 75px;
    padding: 25px 0;
    background: white;
    text-align: center;
}

.terms-privacy a {
    color: #fff;
    text-decoration: none;
    text-align: left;
}

